import React from 'react';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

const BOMHelp = () => (
  <Layout pageName={pages.bomHelp.name}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">BOM Help</h1>
    </div>
    <section className="c-content-centered h-pt-40 h-pb-40">
      <h3>What should the BOM look like?</h3>
      <p>
        The simpler the better. We describe the accepted format below. Although there is some
        tolerance, we recommend to follow the rules to obtain optimal results.
      </p>
      <p>
        You can download{' '}
        <a
          href="/adco_bom_template.csv"
          onClick={customEvent(events.bomHelpDownloadTemplate('csv'))}
        >
          csv template
        </a> or{' '}
        <a
          href="/adco_bom_template.xlsx"
          onClick={customEvent(events.bomHelpDownloadTemplate('xlsx'))}
        >Excel (xlsx) template
      </a>.
      </p>
      <p>
        The first row contains a header with a name for each column. The other rows contain data
        about parts. Required columns are marked with <span className="required">*</span>. The BOM
        also has to contain at least one of the columns marked
        with <span className="required">**</span>.
      </p>
      <h3>Columns</h3>
      <ul className="c-list">
        <li>
          <div className="c-list-heading">Item #</div>
          <div>
            Your identification number. We will keep this one to facilitate communication between
            us. Can be simple sequence 1, 2, 3, ...
          </div>
        </li>
        <li>
          <div className="c-list-heading">Ref Des</div>
          <div>
            The reference designators used by the parts on the PCB silk screen. All of the same part
            number should be in the same spreadsheet cell: i.e., &quot;R3, R4, R5, R6&quot;. You can
            also indicate a contiguous range with a dash: &quot;R3-R6&quot; or &quot;R3-R6, R10,
            R15.&quot;
          </div>
        </li>
        <li>
          <div className="c-list-heading">
            Distributor PN <span className="required">*</span>
            <span className="required">*</span>
          </div>
          <div>
            If you know the distributor part number of the part you want, you can specify it in this
            field and it will take precedence in the search. Note that when you provide only the
            distributor part number (i.e. leave the Manufacturer part number empty), our search
            might be limited only to that particular distributor.
          </div>
        </li>
        <li>
          <div className="c-list-heading">Distributor</div>
          <div>
            The name of the distributor, e.g. Digi-Key or Mouser. It is best to specify distributor
            part number along with particular distributor to avoid confusion.
          </div>
        </li>
        <li>
          <div className="c-list-heading">
            Manufacturer PN <span className="required">*</span>
            <span className="required">*</span>
          </div>
          <div>
            A unique identifier set by the product&apos;s manufacturer to differentiate it from
            similar parts and counterfeits. It helps manufacturers and sellers identify and manage
            stock levels and it puts buyers&apos; minds at ease because they know that they are
            getting the right component.
          </div>
        </li>
        <li>
          <div className="c-list-heading">Manufacturer</div>
          <div>
            The name of the component manufacturer. It&apos;s best to spell out the full name, e.g.,
            &quot;Texas Instruments&quot;, but common abbreviations such as &quot;TI&quot; generally
            work too. The less ambiguity, the better.
          </div>
        </li>
        <li>
          <div className="c-list-heading">
            Quantity <span className="required">*</span>
          </div>
          <div>How many of this specific part you need per board.</div>
        </li>
        <li>
          <div className="c-list-heading">Description</div>
          <div>
            Arbitrary description of yours. This should serve you to know which part is which
            without the need of looking up its part number.
          </div>
        </li>
        <li>
          <div className="c-list-heading">Alternate</div>
          <div>
            If you want to specify that the part is an alternate to the preceding row (meaning the
            two consecutive rows are interchangeable for you), you can fill the Alternate column
            with &quot;True&quot; value, leave the column blank otherwise.
          </div>
        </li>
        <li>
          <div className="c-list-heading">
            Manufacturer 2, Manufacturer PN 2, Distributor PN 2
          </div>
          <div>
            Same as Manufacturer, Manufacturer PN, and Distributor PN resp. They give you the option
            to specify alternate part on the same row (meaning the two parts are interchangeable for
            you).
          </div>
        </li>
        <li>
          <div className="c-list-heading">
            Manufacturer 3, Manufacturer PN 3, Distributor PN 3
          </div>
          <div>Analogous to Manufacturer 2, Manufacturer PN 2, and Distributor PN 2.</div>
        </li>
      </ul>
    </section>
  </Layout>
);

export default BOMHelp;
